<template>
    <div class="conatiner">
    <v-card class="border border-solid border-2 rounded-1 border-warning">
      <v-card-title>
        <div class="row">
          <div class="col-sm-12"></div>
          <h3 class="my-3">Parent Sale</h3>
            <hr class="text-info" />
          <div class="row">
            <div class="col-sm-6 bg-light rounded-2">
              <h4>Invoice No:<strong>{{sale.sale_id}}</strong></h4>
              <h4>Date:<strong>{{ sale.date }}</strong></h4>
            </div>
            <div class="col-sm-6 bg-light rounded-2">
              <h4>Customer:<strong>{{ sale.customer.name }}</strong></h4>
              <h4>Location:<strong>{{ sale.sales_items[0].location.name}}({{ sale.sales_items[0].location.location_id }})</strong></h4>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-card>
        <v-card-title>Return Items</v-card-title>
        <v-card-text>
          <v-data-table :headers="headers" :items="returncart" item-key="id">
            <template v-slot:[`item.unit_price`]="{ item }">{{
              new Intl.NumberFormat().format(item.unit_price)
            }}</template>
             <template v-slot:[`item.return_subtotal`]="">{{
              new Intl.NumberFormat().format(total)
            }}</template>
            <template v-slot:[`item.return_quantity`]="{ item }">
              <v-text-field v-model="item.return_quantity" type="number" min="0" :max="item.quantity" class="w-25" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn @click="removeFromReturnCart(item.index)" icon
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
      <div class="row m-2 p-2">
        <div class="col-lg-3">
          <label>Discount Type:</label>
          <div class="mb-3">
            <multiselect
            v-model="discount_type"
            :options="discount_types"
            :multiple="false"
            placeholder="Select Discount"
          ></multiselect>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="mb-3">
            <label>Dicount Amount:{{ discountPayable }}</label>
            <input
              class="form-control mr-sm-2 d-inline-block"
              type="number"
              v-model="discount_ammount"
            />
          </div>
          <div class="mb-3">
            <label>Return Reason:</label>
            <b-form-textarea
              v-model="return_reason"
              class="form-control mr-sm-2 d-inline-block"
              placeholder="Enter your text here..."
              rows="3"
            ></b-form-textarea>
          </div>
        </div>
        <div class="col-lg-6 bg-light -p-1 rounded-2 border border-1 border-info">
          <h4 class="my-4">Sale Payment Details</h4>
          <hr class="text-info"/>
          <div class="row">
            <div class="col-sm-4"><h5>Type:<strong>{{ `${sale.status==='due'?'Credit Sale':'Cash Sale'}` }}</strong></h5></div>
            <div class="col-sm-4"><h5>Sale Over Due:<strong>{{sale_over_due}}</strong></h5></div>
            <div class="col-sm-4 "><h5 class="text-danger">Custmer Due:<strong>{{ customer_due }}</strong></h5></div>
          </div>
        </div>
      </div>
        </v-card-text>
        <div class="float-end bg-light rounded-1 p-2 border border-2 border-warning">
            <h5>
              Total Return Discount: (-) KES <strong>{{ new Intl.NumberFormat().format(discountPayable.toFixed(2)) }}</strong>
            </h5>
            <h5>
              Sale Over Due: (+) KES <strong>{{ new Intl.NumberFormat().format(sale_over_due.toFixed(2)) }}</strong>
            </h5>
            <h5>
              Customer Due: (-) KES <strong>{{ new Intl.NumberFormat().format(customer_due.toFixed(2)) }}</strong>
            </h5>
            <h5>
              Total Return Tax: (+) KES <strong>{{ new Intl.NumberFormat().format(returnTax.toFixed(2)) }}</strong>
            </h5>
            <h5>
              Return Total: KES <strong>{{ new Intl.NumberFormat().format(returnTotalPayable.toFixed(2)) }}</strong>
            </h5>
          </div>
      </v-card>
      </v-card>
    <Receipt
      :items="returncart"
      :headers="receiptHeaders"
      :total="Number(returnTotalPayable.toFixed(2))"
      :paymentMethod="paymentMethod"
      :receiptNo="paycode"
      @printReceipt="submitreturn"
      v-show="false"
    />
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Receipt from "../printReceipt.vue";
import axios from "@/Axiosconfig.js";
import Swal from "sweetalert2";
import moment from 'moment';

export default {
  name: "PosCart",
  props:{
    sale:Object,
  },
  data() {
    return {
      headers: [
        { text: "Product Name", value: "product_title", sortable: true },
        { text: "Unit Price", value: "unit_price", sortable: true },
        { text: "Sale Quantity", value: "quantity", sortable: true },
        { text: "Return Quantity", value: "return_quantity", sortable: true },
        { text: "Return Subtotal", value: "return_subtotal", sortable: true },
        { text: "Actions", value: "actions", sortable: true },
      ],
      receiptHeaders: [
        { text: "Product Name", value: "title", sortable: true },
        { text: "Unit Price", value: "unit_price", sortable: true },
        { text: "Sale Quantity", value: "quantity", sortable: true },
        { text: "Return Subtotal", value: "return_subtotal", sortable: true },
      ],
      discount_type:'Fixed',
      discount_types: ['Percentage','Fixed'],
      discount_ammount:0,
      return_quantity: 1,
      return_reason:"Need another brand",
      returnTax:0,
      returncart:[],
      sale_over_due:0.00,
      customer_due:0.00,
      returnDiscount:0.00,
      returnTotal:0,
      PhoneNumber: "0743793901",
      beep_warning_sound: require("@/assets/audio/beep-warning.mp3"),
      beepConfirmSound: require("@/assets/audio/confirm.mp3"),
    };
  },
  components: {
    Multiselect,
    Receipt,
  },
  computed: {
    total() {
      return this.returncart.reduce((acc, item) => acc + item.unit_price * item.return_quantity, 0);
    },
    returnTotalPayable(){
      return Math.abs((this.total-(this.discountPayable+this.customer_due))+(this.returnTax+this.sale_over_due));
    },
    discountPayable(){
      if (!this.discount_type || this.discount_ammount === 0) {
        return 0;
      }

      if (this.discount_type === 'Percentage') {
        return (this.discount_ammount * 0.01)*this.total;
      } else {
        return this.discount_ammount;
      }
    },
    hiddenPhone() {
      // Replace the first 4 characters with x
      const hiddenChars = this.PhoneNumber.substr(0, 4).replace(/./g, "x");
      // Return the hidden text with the last 4 digits
      return hiddenChars + "-" + this.PhoneNumber.substr(-4);
    },
    formattedPhoneNumber() {
      // check if phone number starts with 0
      if (/^0/.test(this.PhoneNumber)) {
        // replace leading 0 with 254
        return this.PhoneNumber.replace(/^0/, "254");
      } else if (/^254/.test(this.PhoneNumber)) {
        // phone number already starts with 254, so ignore
        return this.PhoneNumber;
      } else {
        // phone number doesn't start with 0 or 254, so return as is
        return this.PhoneNumber;
      }
    },
  },
  created(){
    this.addToReturnCart();
  },
  mounted() {
    this.sale={...this.sale,date:moment(this.sale.date).format('DD-MM-YYYY HH:MM:SS')}
    this.customer_due=this.sale.balance_due;
    // Listen for the custom event from the parent component
    //this.$parent.$on('confirmReturn', this.handleReturn());
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    this.$parent.$off('confirmReturn', this.handleReturn());
  },
  methods: {
    playBeepSound(audio_url) {
      const audio = new Audio(audio_url);
      audio.play();
    },
    addToReturnCart() {
      const items=this.sale.sales_items;
      console.log(this.sale)
      this.customer=this.sale.customer;
      this.discount_type='Fixed';
      for(let item of items){
      this.returnDiscount+=Number(item.discount_amount);
      this.discount_ammount+=Number(item.discount_amount);
      this.returnTax+=item.tax_amount;
      item.stock_item.product.title=`${item.stock_item.product.title} ${item.stock_item.variation.title ? item.stock_item.variation.title : ''}`
      const product = item.stock_item.product;
      var sku=sku=item.stock_item.variation.sku ? item.stock_item.variation.sku : item.stock_item.product.sku
      var unit_price = item.unit_price;
      var existingItem = this.returncart.find(e => e.sku === sku);
    if (existingItem) {
        existingItem.quantity += 1;
    } else {
      //console.log(item)
        // Item does not exist in the cart, add it
        this.returncart.push({
            sku: sku,
            product_title:product.title,
            variation:item.variation,
            quantity:item.qty,
            return_quantity:this.return_quantity,
            unit_price,
            return_subtotal:0,
        });
      }
    }
    this.amountPaid=this.TotalPayable;
    },
    removeFromReturnCart(index) {
      this.returncart.splice(index, 1);
    },
    submitReturn(data) {
      axios
        .post(`salesreturns/`,data)
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: res.data.icon,
            title: res.data.title,
            html: res.data.msg,
            showConfirmButton: false,
            timer: 1500,
          }).then(()=>{
          this.$emit("printReceipt");
          });
          this.playBeepSound(this.beepConfirmSound);
          this.clearValues();
        })
        .cath((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
            timer: 3000,
          });
        });
    },
    handleReturn() {
      var formdata = new FormData();
      this.returncart.forEach((item) => {
        formdata.append("sku", item.sku);
        formdata.append("qty", item.quantity);
      });
      formdata.append("sale_id",this.sale.sale_id);
      formdata.append("return_total",this.returnTotalPayable.toFixed(2));
      formdata.append("return_reason",this.return_reason)
      formdata.append("attendant",JSON.parse(sessionStorage.user).user.id)
      this.submitReturn(formdata);
    },
    handleSubmit() {},
    clearValues() {
      this.returncart = [];
      this.returnTotal = 0;
    },
  },
};
</script>